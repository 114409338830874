import {CircularProgress} from "@mui/material";
import React from "react";

const ScreenLoader = () => {
  return (
    <div className="w-full min-h-screen flex items-center justify-center space-x-4">
      <span>Загрузка ...</span>
      <CircularProgress color="success"/>
    </div>
  );
};

export default ScreenLoader;